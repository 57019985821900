import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["notificationPopup", "enableNotification"];

  connect() {
    if (this.notificationPopupTarget) {
      this.showNotificationPermissionPopup();
      this.seekPermission();
    }
  }

  seekPermission() {
    if (!("Notification" in window)) {
      console.error("This browser does not support desktop notification");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      this.addServiceWorker();
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      this.enableNotificationTarget.addEventListener("click", () => {
        Notification.requestPermission().then(this.addServiceWorker());
        this.notificationPopupTarget.classList.add("hide-notification-popup");
      });
    }
  }

  addServiceWorker() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/serviceworker.js");
      navigator.serviceWorker.ready
        .then(function (registration) {
          registration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: window.vapidPublicKey,
            })
            .then(function (subscription) {
              fetch("/desktop_browsers", {
                body: JSON.stringify({
                  browser: subscription.toJSON(),
                }),
                method: "POST",
                dataType: "script",
                credentials: "include",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              });
            });
        })
        .catch(function (error) {
          console.error("Registration failed", ":^(", error);
        });
    }
  }

  hideNotificationPermissionPopup() {
    this.notificationPopupTarget.classList.add("hide-notification-popup");
    fetch("/members/browser_notifications", {
      method: "PUT",
      dataType: "script",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        window.location.reload();
      })
      .catch(err => {
        console.error("Disabling browser notification failed", ":^(", err);
      });
  }

  showNotificationPermissionPopup() {
    if (!("Notification" in window)) {
      console.warn("This browser does not support desktop notifications.");
    } else if (
      Notification.permission === "default" &&
      !window.location.href.includes("profile?new_state=true")
    ) {
      this.notificationPopupTarget.classList.remove("hide-notification-popup");
    }
  }
}
